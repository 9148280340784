var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EditLayout",
    {
      attrs: {
        item: _vm.item,
        "supports-editor": false,
        "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save")
      },
      on: {
        submit: function($event) {
          _vm.isNew ? _vm.create() : _vm.edit()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "row", attrs: { slot: "middle" }, slot: "middle" },
        [
          _c("BaseInput", {
            staticClass: "col-md-6",
            attrs: {
              value: "#" + _vm.item.hex,
              label: "HEX",
              "max-length": "6",
              type: "color"
            },
            on: { input: _vm._updateHex }
          }),
          _c("BaseInput", {
            staticClass: "col-md-6",
            attrs: {
              value: _vm.item.hex,
              label: "Hex by Typing",
              placeholder: "Entere hex without #",
              "max-length": "6",
              type: "text"
            },
            on: { input: _vm._updateHex }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "sidebar-middle" }, slot: "sidebar-middle" },
        [
          _c(
            "BaseInput",
            { attrs: { label: "Transparent", "is-slider-checkbox": "" } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.transparent,
                    expression: "item.transparent"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  "true-value": "1",
                  "false-value": "0"
                },
                domProps: {
                  checked: Array.isArray(_vm.item.transparent)
                    ? _vm._i(_vm.item.transparent, null) > -1
                    : _vm._q(_vm.item.transparent, "1")
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.item.transparent,
                      $$el = $event.target,
                      $$c = $$el.checked ? "1" : "0"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.item, "transparent", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.item,
                            "transparent",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.item, "transparent", $$c)
                    }
                  }
                }
              })
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }